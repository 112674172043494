.text-primary {
  color: #e4a328;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.mt-md {
  margin-top: 2em;
}

.white-space-no-wrap {
  white-space: nowrap;
}

@media screen and (max-width: 480px) {
  #page-wrapper .button {
    padding: 0 2.25em;
  }
}
