#logo img {
    width: 7%;
    margin: 10px 0 0 10px;
}

#titleBar .title img {
    width: 10%;
    padding: 5px 0;
}

.layout {
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
}

.submenu {
    user-select: none;
    display: none;
    position: absolute;
}

#lng-selector li span {
    display: block;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
}

#lng-selector li a.selected,
#navPanel .link.depth-1.selected {
    color: #e4a328 !important;
}

#lng-selector li a:hover {
    color: #e4a328 !important;
}
